import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalenderSlider from "./slides/CalenderSlider";
import { FaWifi } from "react-icons/fa";
import { RiComputerLine } from "react-icons/ri";
import { IoSnowSharp } from "react-icons/io5";
import { FaBath } from "react-icons/fa6";
import { RadioGroup } from "@mui/material";
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

const BookingDoubleRoom = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [year] = useState(new Date().getFullYear());
  const [month, setMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [adults, setAdults] = useState(null);
  const [children, setChildren] = useState(null);
  const [room, setRoom] = useState("Deluxe Double Room");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the booking with the captured values
    const bookingDetails = {
      checkIn,
      checkOut,
      adults,
      children,
      room,
    };

    console.log("Booking details:", bookingDetails);

    // Navigate to the next page with the booking details
    navigate("/contact", { state: bookingDetails });

    setTimeout(() => {
      scroller.scrollTo("cform", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 100); // Adjust the timeout as needed
  };
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthSelect = (eventKey) => {
    setSelectedMonth(eventKey);
  };

  return (
    <>
      <div
        className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-bookNow"
        data-wow-delay="0.1s"
      >
        <div
          className="container py-5 d-flex justify-content-start"
          style={{ flexDirection: "column" }}
        >
          <h3
            className="display-4 text-white mb-3 animated slideInDown"
            style={{ fontSize: "40px" }}
          >
            Book now
          </h3>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a className="text-white" href="/">
                  Home
                </a>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Book now
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="spad3">
        <div className="container">
          <div className="row  d-flex justify-content-center">
            <div className="col-lg-10 col-xl-9 col-md-12">
              <div className="row">
                <div className="col-lg-12">
                  <h4>Deluxe Double Room</h4>
                </div>
                <div
                  className="col-lg-12"
                  style={{ borderTop: "1px solid gray" }}
                >
                  <img
                    width="30%"
                    className="py-4"
                    src="img/room/room2.png"
                    alt="Deluxe Double Room"
                  />
                </div>
                <div
                  className="col-lg-12 d-flex justify-content-end"
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <div
                    className="d-flex flex-column justify-content-center align-items-center p-0"
                    style={{ backgroundColor: "#f2f3f4", width: "150px" }}
                  >
                    <p>Starting From</p>
                    <h4>$ 175</h4>
                  </div>
                </div>
                <div
                  className="col-lg-12 d-flex flex-column "
                  style={{ gap: "10px" }}
                >
                  <a className="mt-3"> Deluxe Double Room </a>
                  <div className="d-flex" style={{ gap: "5px" }}>
                    <FaWifi />
                    <RiComputerLine />
                    <IoSnowSharp />
                    <FaBath />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 py-5">
                  <div
                    className="col-lg-11 col-sm-11 mt-4 py-4 px-3"
                    style={{ backgroundColor: "#f2f3f4" }}
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="row g-2 align-items-center">
                        <div className="col-md-10">
                          <div className="row g-2">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="date1">Check in</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="date1"
                                  value={checkIn}
                                  onChange={(e) => setCheckIn(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="date2">Check out</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="date2"
                                  value={checkOut}
                                  onChange={(e) => setCheckOut(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label htmlFor="adult">No of Adult</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="adult"
                                  value={adults}
                                  min="0"
                                  max="3"
                                  onChange={(e) =>
                                    setAdults(parseInt(e.target.value))
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label htmlFor="child">No of Child</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="child"
                                  value={children}
                                  min="0"
                                  max="3"
                                  onChange={(e) =>
                                    setChildren(parseInt(e.target.value))
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 d-flex align-items-center">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingDoubleRoom;
